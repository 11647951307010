<script lang="ts" setup>
import { Link } from "@inertiajs/vue3";
import { computed, useAttrs } from "vue";

interface Props {
    variant?: "solid" | "outline";
    color?: "primary" | "secondary" | "white" | "red" | "black";
    className?: string;
    href?: string;
    target?: string;
}

const baseStyles = {
    solid: "group inline-flex items-center justify-center rounded-full border focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-center text-sm  hover:no-underline",
    outline:
        "group inline-flex items-center justify-center rounded-full border focus:ring-4 focus:outline-none font-medium text-center text-sm hover:no-underline",
};

const variantStyles = {
    solid: {
        primary: "text-white visited:text-white bg-blue-700 hover:bg-blue-800",
        secondary:
            "text-blue-700 visited:text-blue-700 hover:text-white border-blue-700 hover:bg-blue-500 focus:ring-blue-300",
        white: "text-gray-900 visited:text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200",
        black: "text-white visited:text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300",
        red: "text-white visited:text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300",
    },
    outline: {
        primary:
            "text-blue-700 visited:text-blue-700 hover:text-white border-blue-700 hover:bg-blue-800 focus:ring-blue-300",
        secondary:
            "text-gray-900 visited:text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300",
        white: "text-white visited:text-white ring-slate-700 hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white hover:bg-gray-700",
        black: "text-black visited:text-black ring-slate-700 hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white hover:bg-gray-700",
        red: "text-red-700 visited:text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ",
    },
};

const props = withDefaults(defineProps<Props>(), {
    variant: "solid",
    color: "primary",
    className: "px-4 py-2",
    href: null,
    target: null,
});

// access a component's fallthrough attributes in
const attrs = useAttrs();

const classes = computed(() => {
    return [].concat(
        baseStyles[props.variant],
        variantStyles[props.variant][props.color],
        props.className,
        attrs.class,
    );
});
</script>

<template>
    <template v-if="href">
        <a
            v-if="props.target"
            :href="props.href"
            :class="classes"
            :target="props.target"
        >
            <slot></slot>
        </a>
        <Link
            v-else
            :href="props.href"
            :class="classes"
        >
            <slot></slot>
        </Link>
    </template>

    <button
        v-else
        :class="classes"
    >
        <slot></slot>
    </button>
</template>
